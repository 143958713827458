import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    Page,
    PermissionKeys,
    RowActions,
    SearchInput,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParamsContext,
    useSelectedOrganisation,
} from '../../../shared';
import { useCustomerList, useDeleteCustomer } from '../../hooks/customers.hook';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ICard } from '../../models/card.model';
import { CustomerType } from '../../enums/customer-type.enum';
import { Button, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { CustomerFilter } from '../../components/customers/customer-filter.component';
import { DownloadCard } from '../../components/customers/download-card.component';

export const CustomersPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organisation } = useSelectedOrganisation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.CUSTOMERS_WRITE);

    const {
        queryParams: { page, pageSize, sort, search, filter },
        debouncedQueryParams,
        setFilter,
        setPage,
        setPageSize,
        setSort,
        setSearch,
    } = useQueryParamsContext('customers', {
        sort: [{ field: 'name', sort: 'asc' }],
    });

    const { mutateAsync: deleteCustomer } = useDeleteCustomer();
    const { data: customers, isPending } = useCustomerList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        organisationId: organisation?.id,
        types: filter.types || [],
        search: debouncedQueryParams.search,
    });

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('name'), minWidth: 150, flex: 1, sortable: true },
        {
            field: 'card',
            headerName: t('card'),
            minWidth: 150,
            flex: 1,
            sortable: true,
            valueFormatter: (value: ICard) => value.code,
        },
        {
            field: 'type',
            headerName: t('type'),
            minWidth: 150,
            flex: 1,
            sortable: true,
            valueFormatter: (value: CustomerType) => t(value),
        },
        {
            field: ' ',
            headerName: '',
            width: 250,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <>
                    <DownloadCard cardId={row?.card?.id} variant="icon" />
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`${row?.id}`);
                        }}
                    >
                        <Search />
                    </IconButton>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`${row?.id}/edit`)}
                            onDelete={() => deleteCustomer(row.id)}
                            deleteWarningTitle={t('deleteCustomer')}
                            deleteWarningText={t('deleteCustomerText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('customers')}
            filter={<CustomerFilter filter={filter} onChange={setFilter} />}
            actions={[
                <SearchInput value={search} setValue={setSearch} />,
                hasWritePermission && (
                    <Button component={Link} to="new" color="primary" variant="contained">
                        {t('newCustomer')}
                    </Button>
                ),
            ]}
        >
            <TableComponent
                rows={customers?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={customers?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />
        </Page>
    );
};
