import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Theme } from '@mui/material';

export function getTypography({ palette, typography }: Theme, options?: TypographyOptions): TypographyOptions {
    const fontFamily = options?.fontFamily ? options.fontFamily : typography.fontFamily;

    return {
        h1: {
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            paddingRight: 24,
            borderRight: `solid 1px ${palette.grey['300']}`,
            height: 55,
            width: 216,
            fontFamily,
            ...options?.h1,
        },

        h2: {
            fontSize: 24,
            marginBottom: 24,
            fontFamily,
            ...options?.h2,
        },

        h3: {
            fontFamily,
            ...options?.h3,
        },

        h4: {
            fontFamily,
            ...options?.h4,
        },

        h5: {
            fontFamily,
            ...options?.h5,
        },

        h6: {
            fontFamily,
            ...options?.h6,
        },

        subtitle1: {
            fontSize: 18,
            marginBottom: 16,
            fontFamily,
            ...options?.subtitle1,
        },

        subtitle2: {
            fontSize: 16,
            marginBottom: 16,
            fontFamily,
            ...options?.subtitle2,
        },

        body1: {
            fontFamily,
            ...options?.body1,
        },

        body2: {
            fontFamily,
            ...options?.body2,
        },

        button: {
            textTransform: 'none',
            fontWeight: 'bold',
            fontFamily,
            ...options?.button,
        },

        overline: {
            fontFamily,
            ...options?.overline,
        },

        caption: {
            fontSize: 14,
            marginBottom: 6,
            fontFamily,
            ...options?.caption,
        },

        description: {
            fontSize: 12,
            marginBottom: 4,
            fontFamily,
            ...options?.description,
        },
    };
}
