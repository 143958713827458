import React, { FC } from 'react';
import { formatAmount, formatPrice, formatProductPrice, ISaleItem, mapPriceFromApi } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface Props {
    saleItems: ISaleItem[] | void;
}

export const SaleDetailSaleItems: FC<Props> = ({ saleItems }) => {
    const { t } = useTranslation();

    return (
        <TableContainer sx={{ maxHeight: '300px' }}>
            <Table stickyHeader sx={{ th: { backgroundColor: '#FFF', borderBottomWidth: '2px' } }}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('productName')}</TableCell>
                        <TableCell>{t('productPrice')}</TableCell>
                        <TableCell>{t('amount')}</TableCell>
                        <TableCell>{t('totalPrice')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ maxHeight: '200px' }}>
                    {saleItems?.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.productName}</TableCell>
                            <TableCell>
                                {formatProductPrice(
                                    item.measurementUnit,
                                    mapPriceFromApi(item.measurementUnit, item.productPrice) || 0,
                                    t,
                                )}
                            </TableCell>
                            <TableCell>{formatAmount(item.measurementUnit, item.amount, t)}</TableCell>
                            <TableCell>{formatPrice(item.productPrice * item.amount)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
