import { Button } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    FormGrid,
    IParams,
    Page,
    PermissionKeys,
    RemoveModal,
    useDeleteProduct,
    useHasPermission,
    useProduct,
} from '../../../shared';
import { ProductDetails, ProductRules } from '../../components';

export const ProductDetailPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasProductWritePermission = hasPermission(PermissionKeys.PRODUCTS_WRITE);
    const hasSaleQuotumWritePermission = hasPermission(PermissionKeys.SALE_QUOTA_WRITE);

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: product, isPending } = useProduct(id);
    const { mutateAsync: deleteProduct } = useDeleteProduct();

    const onDelete = useCallback(async () => {
        await deleteProduct(id);
        navigate('/admin/products');
    }, [deleteProduct, navigate, id]);

    return (
        <Page
            onBack={() => navigate('/admin/products')}
            title={product?.name}
            loading={isPending}
            actions={
                hasProductWritePermission && [
                    hasSaleQuotumWritePermission && !product?.saleQuotum && (
                        <Button
                            onClick={() =>
                                product && navigate(`/admin/sale-quota/new`, { state: { product: product } })
                            }
                            sx={{ textDecoration: 'underline' }}
                        >
                            {t('addSaleQuotum')}
                        </Button>
                    ),
                    <RemoveModal
                        handleDelete={onDelete}
                        button={
                            <Button color="primary" variant="outlined">
                                {t('delete')}
                            </Button>
                        }
                        title={t('deleteProduct')}
                        text={t('deleteProductText')}
                    />,
                    <Button onClick={() => navigate(`./edit`)} variant="contained">
                        {t('edit')}
                    </Button>,
                ]
            }
        >
            {product && (
                <FormGrid xs={12} md={product.saleQuotum ? 6 : 12}>
                    <ProductDetails product={product} />
                    {product.saleQuotum && <ProductRules saleQuotum={product.saleQuotum} />}
                </FormGrid>
            )}
        </Page>
    );
};
