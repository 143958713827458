import { BaseClient, shopApi } from '../../shared';
import { ICustomer, ICustomerInfo, ICustomerInfoQuery, ICustomerQuery, ISaveCustomer } from '../models/customer.model';

class CustomersClient extends BaseClient<ICustomer, ISaveCustomer, ICustomerQuery> {
    constructor() {
        super(shopApi, '/customers');
    }

    public async getCustomerInfo(query: ICustomerInfoQuery): Promise<ICustomerInfo> {
        const { data } = await shopApi.get(`/customers/info`, { params: query });
        return data;
    }
}

export const customersClient = new CustomersClient();
