import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGrid, Page, useSelectedOrganisation } from '../../../shared';
import { useSettings, useUpdateSettings } from '../../hooks';
import { ISettingsForm } from '../../models';
import { useSettingsSchema } from '../../validators';
import { GeneralSettings } from '../../components/settings/general-settings.component';
import { SolidaritySettings } from '../../components/settings/solidarity-settings.component';

export const SettingsPage: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();

    const { data: settings, isFetching } = useSettings(organisation?.id);
    const { mutateAsync: updateSettings, isPending: isUpdating } = useUpdateSettings();

    const form = useForm<ISettingsForm>({
        mode: 'all',
        resolver: yupResolver(useSettingsSchema()),
    });

    useEffect(() => {
        if (settings) {
            form.reset({ ...settings, additionalSolidarityCharge: settings.additionalSolidarityCharge || null });
        }
    }, [settings, form]);

    const onSubmit = useCallback(
        async (item: ISettingsForm) => {
            if (organisation?.id) {
                await updateSettings({
                    ...item,
                    additionalSolidarityCharge: item.solidarityPriceIsPurchasePrice
                        ? null
                        : item.additionalSolidarityCharge,
                    organisationId: organisation.id,
                    saleTicketMessage: item.saleTicketMessage || '',
                });
            }
        },
        [organisation?.id, updateSettings],
    );

    const actions = useMemo(
        () => [
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(onSubmit)}
                key="save"
                disabled={isFetching || isUpdating}
            >
                {t('save')}
            </Button>,
        ],
        [form, onSubmit, isFetching, isUpdating, t],
    );

    return (
        <Page title={t('settings')} actions={actions} loading={isFetching || isUpdating}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <FormGrid xs={12} lg={6} containerProps={{ pr: 0 }} sx={{ '.MuiFormControl-root': { mb: 0 } }}>
                        <GeneralSettings />
                        <SolidaritySettings />
                    </FormGrid>
                </form>
            </FormProvider>
        </Page>
    );
};
