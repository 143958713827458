import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DialogTitleWithClose,
    SortOrder,
    TableComponent,
    useQueryParamsContext,
    useSelectedOrganisation,
    useShop,
} from '../../../shared';
import { Button, Dialog, DialogActions, DialogContent, InputAdornment, TextField } from '@mui/material';
import { CustomerType } from '../../../customer/enums/customer-type.enum';
import { Search } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';
import { ICard } from '../../../customer/models/card.model';
import { useCustomerList } from '../../../customer/hooks/customers.hook';

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const SearchOtherCustomerModal: FC<Props> = ({ open, handleClose }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { setCustomerCredentials } = useShop();

    const {
        queryParams: { page, pageSize, sort, search },
        debouncedQueryParams,
        setPage,
        setPageSize,
        setSort,
        setSearch,
    } = useQueryParamsContext('customers', {
        sort: [{ field: 'name', sort: 'asc' }],
    });

    const { data: customers, isPending } = useCustomerList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        organisationId: organisation?.id,
        search: debouncedQueryParams.search,
    });

    const selectAndClose = useCallback(
        (customerId: string, type: CustomerType, card?: ICard) => {
            setCustomerCredentials({ customerId, isSocialCustomer: false, cardCode: card?.code ?? undefined });
            handleClose();
        },
        [handleClose, setCustomerCredentials],
    );

    const columns: GridColDef[] = [
        {
            field: 'card',
            headerName: t('card'),
            minWidth: 100,
            flex: 0.5,
            valueFormatter: (value: ICard) => value?.code,
        },
        {
            field: 'name',
            headerName: t('name'),
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'type',
            headerName: t('type'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (value: CustomerType) => t(value),
        },
    ];

    return (
        <Dialog open={open} fullWidth={true} maxWidth="lg" disableRestoreFocus sx={{ minHeight: '100vh' }}>
            <DialogTitleWithClose onClose={handleClose}>{t('searchOtherCustomer')}</DialogTitleWithClose>
            <DialogContent>
                <TextField
                    sx={{ mt: 1, mb: 1 }}
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                    label={t('searchOnNameOrCode')}
                    size="small"
                    data-testid="search-input"
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        },
                    }}
                    autoFocus
                />

                <TableComponent
                    rows={customers?.data || []}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    rowCount={customers?.pagination.size || 0}
                    loading={isPending}
                    sortModel={sort}
                    onSortModelChange={setSort}
                    onRowClick={({ row: { id, type, card } }) => selectAndClose(id, type, card)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
