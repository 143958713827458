import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { customersClient } from '../clients/customers.client';
import { ICustomer, ICustomerInfo, ICustomerInfoQuery, ICustomerQuery } from '../models/customer.model';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const client = customersClient;
const listKey = QueryKeys.CUSTOMERS;
const itemKey = QueryKeys.CUSTOMER;

export function useCustomerList(...args: UseListParams<ICustomer, ICustomerQuery>) {
    return useList(client, listKey, ...args);
}

export function useCustomer(...args: UseItemParams<ICustomer>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveCustomer() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteCustomer() {
    return useDelete(client, listKey);
}

export function useCustomerInfo(query: ICustomerInfoQuery) {
    return useQuery<ICustomerInfo, AxiosError>({
        queryKey: [itemKey, query],
        queryFn: () => client.getCustomerInfo(query),
        enabled: !!query.organisation && (!!query.id || !!query.code),
        retry: false,
    });
}
