import React, { FC, useMemo } from 'react';
import { formatPrice, FormGrid, ISale, LabelValue, useSelectedOrganisation, useShop, Widget } from '../../../../shared';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBudget } from '../../../../admin/hooks';
import { FamilySituation } from '../family-situation/family-situation.component';
import { ISupportCaseInfo } from '../../../models/support-case.model';
import { ISupportPeriod } from '../../../models/support-period.model';
import { useHasSupportCaseAccess } from '../../../utils/has-support-case-access.util';
import { useTotalBudget } from '../../../../shop/utils';

interface Props {
    supportCase: ISupportCaseInfo;
    currentSale?: ISale;
    activeSupportPeriod?: ISupportPeriod;
}
export const SupportCaseInfoData: FC<Props> = ({ supportCase, currentSale, activeSupportPeriod }) => {
    const { t } = useTranslation();
    const { customer } = useShop();
    const { organisation } = useSelectedOrganisation();

    const hasSupportCaseAccess = useHasSupportCaseAccess(supportCase, organisation);
    const primary = useMemo(() => supportCase?.supportees?.find((sup) => sup?.isPrimary), [supportCase]);
    const showWarningPanel = window.carity.environment.showWarningPanel;

    const { data: budget } = useBudget(organisation?.id);
    const totalBudget = useTotalBudget(supportCase, budget);
    const spentBudget = (currentSale?.spentBudget ?? supportCase?.spentBudget) || 0;
    const activeSupportTypeInfo = activeSupportPeriod?.supportTypesInfo?.find(
        ({ supportTypeId }) => supportTypeId === window.carity.environment.supportTypeId,
    );

    const remainingBudget = totalBudget && formatPrice(totalBudget - spentBudget);
    const remainingCredit =
        activeSupportTypeInfo?.credit &&
        formatPrice(activeSupportTypeInfo.credit - (activeSupportTypeInfo?.spendCredit || 0));

    return (
        <Widget sx={{ minHeight: 'fit-content', mb: 0 }}>
            <Stack spacing={2} justifyContent="space-between" height="100%">
                <Grid container>
                    <Grid item xs={6} lg={8}>
                        <FormGrid xs={12} lg={6} containerProps={{ rowSpacing: 1, pr: 0 }}>
                            {customer?.cardCode && <LabelValue label={t('customerCard')} value={customer.cardCode} />}

                            <LabelValue
                                label={t('customer')}
                                value={primary && `${primary?.firstName || t('anonymous')} ${primary?.lastName || ''}`}
                            />

                            {remainingBudget && (
                                <LabelValue
                                    label={t('budgetLabelShop.' + budget?.period || '')}
                                    value={
                                        <Typography
                                            variant="caption"
                                            color={totalBudget && totalBudget < spentBudget ? 'error' : 'success'}
                                        >
                                            {remainingBudget}
                                        </Typography>
                                    }
                                />
                            )}

                            {!activeSupportPeriod?.endDate && !showWarningPanel && (
                                <LabelValue label={t('supportPeriodUntil')} value="-" />
                            )}

                            {remainingCredit && showWarningPanel && (
                                <LabelValue label={t('remainingCredit')} value={remainingCredit} />
                            )}
                        </FormGrid>
                    </Grid>

                    <Grid item xs={6} lg={4}>
                        <FamilySituation supportCase={supportCase} />
                    </Grid>
                </Grid>

                {hasSupportCaseAccess && (
                    <Button
                        target="_blank"
                        href={`${window.carity.environment.supportUrl}/support/support-cases/${supportCase?.supportCaseId}/detail`}
                        variant="outlined"
                        size="small"
                        sx={{ width: '100%' }}
                    >
                        {t('goToDetailSupportCase')}
                    </Button>
                )}
            </Stack>
        </Widget>
    );
};
