import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGrid, LabelValue, Widget } from '../../../shared';
import { ICustomer } from '../../models/customer.model';

interface Props {
    customer?: ICustomer;
}

export const CustomerInfo: FC<Props> = ({ customer }) => {
    const { t } = useTranslation();

    return (
        <Widget sx={{ minHeight: 'fit-content', mb: 0 }}>
            <FormGrid xs={4}>
                <LabelValue label={t('card')} value={(customer as ICustomer)?.card.code} />
                <LabelValue label={t('customer')} value={(customer as ICustomer)?.name} />
                <LabelValue label={t('type')} value={t((customer as ICustomer)?.type)} />
            </FormGrid>
        </Widget>
    );
};
