import { CustomerType } from '../../customer/enums/customer-type.enum';
import { ICustomerContext, IProduct, SelectedProductType } from '../../shared';

export function getProductPrice(customer?: ICustomerContext, product?: IProduct | SelectedProductType) {
    switch (customer?.type) {
        case CustomerType.SOCIAL:
            return product?.price;
        case CustomerType.SOLIDARITY:
            return product?.solidarityPrice;
        case CustomerType.PROJECT:
            return product?.lastPurchasePrice;
        default:
            return undefined;
    }
}
