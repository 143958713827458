import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DialogTitleWithClose,
    SortOrder,
    TableComponent,
    useQueryParams,
    useSelectedOrganisation,
    useShop,
} from '../../../shared';
import { Button, Dialog, DialogActions, DialogContent, InputAdornment, TextField } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Search } from '@mui/icons-material';
import { ICard } from '../../../customer/models/card.model';
import { useSupportCases } from '../../../customer/hooks/support-case.hook';

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const SearchSocialCustomerModal: FC<Props> = ({ open, handleClose }) => {
    const { t } = useTranslation();
    const { shareSupportCases } = window.carity.environment;
    const { organisation } = useSelectedOrganisation();
    const { setCustomerCredentials } = useShop();

    const { page, pageSize, setPage, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'lastName, firstName', sort: 'asc' }],
    });

    const { data: socialCustomers, isPending } = useSupportCases(
        {
            page,
            pageSize,
            sortBy: sort[0].field.split(', '),
            sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
            search: debouncedSearch,
        },
        shareSupportCases || !!organisation?.id,
        shareSupportCases ? undefined : organisation?.id,
    );

    const selectAndClose = useCallback(
        (supportCaseId: string, cards?: ICard[]) => {
            setCustomerCredentials({
                supportCaseId,
                cardCode: cards?.[0]?.code ?? undefined,
                isSocialCustomer: true,
            });
            handleClose();
        },
        [handleClose, setCustomerCredentials],
    );

    const columns: GridColDef[] = [
        {
            field: 'cards',
            headerName: t('card'),
            minWidth: 100,
            flex: 0.5,
            sortable: false,
            valueFormatter: (value: ICard[]) => value.map(({ code }) => code).join(', '),
        },
        {
            field: 'lastName, firstName',
            headerName: t('name'),
            minWidth: 200,
            flex: 1,
            renderCell: ({
                row: {
                    primarySupportee: { firstName, lastName, anonymous },
                },
            }: GridCellParams) => (anonymous ? t('anonymous') : `${firstName} ${lastName}`),
        },
    ];

    return (
        <Dialog open={open} fullWidth={true} maxWidth="lg" disableRestoreFocus>
            <DialogTitleWithClose onClose={handleClose}>{t('searchSocialCustomer')}</DialogTitleWithClose>
            <DialogContent>
                <TextField
                    sx={{ mt: 1, mb: 1 }}
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                    label={t('searchOnNameOrCode')}
                    size="small"
                    data-testid="search-input"
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        },
                    }}
                    autoFocus
                />

                <TableComponent
                    rows={socialCustomers?.data || []}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    rowCount={socialCustomers?.pagination.size || 0}
                    loading={isPending}
                    sortModel={sort}
                    onSortModelChange={setSort}
                    onRowClick={({ row: { id, cards } }) => selectAndClose(id, cards)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
