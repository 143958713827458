import { IOrganisation, PermissionKeys, useHasPermission } from '../../shared';
import { useMemo } from 'react';
import { ISupportCaseInfo } from '../models/support-case.model';

export function useHasSupportCaseAccess(
    selectedSupportCase: ISupportCaseInfo | null | undefined,
    organisation: IOrganisation | null | undefined,
) {
    const { hasPermission } = useHasPermission();

    return useMemo(
        () =>
            hasPermission([PermissionKeys.SUPPORT_CASES_READ, PermissionKeys.SUPPORT_CASE_READ], false) &&
            (window.carity.environment.shareSupportCases ||
                selectedSupportCase?.organisations.find((org) => org.id === organisation?.id)),
        [hasPermission, selectedSupportCase, organisation],
    );
}
