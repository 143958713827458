import { alpha, Components, createTheme, Theme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { createBaseTheme } from './base.theme';
import { getComponents } from '../utils/components.util';
import { getPalette } from '../utils/palette.util';
import { ITheme } from '../../shared';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { getTypography } from '../utils/typography.util';

export const createAntwerpTheme = (theme: ITheme): Theme => {
    const palette = getPalette(theme.color);
    const antwerpTheme = createTheme({ palette });
    const typography = getTypography(antwerpTheme, typographyOverride());
    const components = getComponents(antwerpTheme, componentOverride(antwerpTheme));

    return deepmerge(
        createBaseTheme(theme),
        createTheme(antwerpTheme, { shape: { borderRadius: 0 }, palette, typography, components }),
    );
};

function typographyOverride(): TypographyOptions {
    return {
        fontFamily: '"Sun Antwerpen", sans-serif',
        h1: { fontWeight: 'bold' },
        h2: { fontWeight: 'bold' },
        h3: { fontWeight: 'bold' },
        h4: { fontWeight: 'bold' },
        h5: { fontWeight: 'bold' },
        h6: { fontWeight: 'bold' },
        subtitle1: { fontWeight: 'bold' },
        subtitle2: { fontWeight: 'bold' },
        button: { textTransform: 'none', fontWeight: 'bold' },
    };
}

function componentOverride({ palette }: Theme): Components {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 48,
                    [':focus']: {
                        outlineWidth: '2px',
                        outlineStyle: 'solid',
                        outlineColor: palette.primary.main,
                        outlineOffset: '1px',
                    },
                },
                sizeSmall: {
                    height: 30,
                },
            },
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '.MuiListSubheader-root': {
                        marginTop: '12px',
                        lineHeight: '30px',
                        fontSize: '16px',
                        color: 'black',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.filter .MuiInputBase-root': {
                        height: 35,
                    },
                    '&.filter .MuiFormLabel-root': {
                        fontSize: 14,
                        marginBottom: 2,
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#141414',
                    marginBottom: 6,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginBottom: 6,
                    '&.Mui-error': {
                        marginLeft: 0,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    transform: 'none',
                    color: '#141414',
                    marginBottom: 6,
                    position: 'relative',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&::after': {
                        display: 'none',
                    },
                    '&::before': {
                        display: 'none',
                    },
                },
                input: {
                    borderStyle: 'solid',
                    borderColor: '#6f6f6f',
                    borderWidth: 1,
                    height: 48,
                    boxSizing: 'border-box',
                    padding: '0 1.5rem',

                    '&::placeholder': {
                        opacity: 0.6,
                        color: '#141414',
                    },
                    '&:focus': {
                        borderColor: palette.primary.main,
                        boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: { '.MuiInputBase-root': { padding: '6px' } },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&::after': { display: 'none' },
                    '&::before': { display: 'none' },
                    fieldset: { display: 'none' },
                    borderStyle: 'solid',
                    borderColor: '#6f6f6f',
                    borderWidth: 1,
                    boxSizing: 'border-box',
                    height: 48,
                    '&.Mui-focused': {
                        borderStyle: 'none',
                        borderColor: palette.primary.main,
                        boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    marginTop: '0px !important',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingLeft: 12,
                    display: 'flex',
                    alignItems: 'center',
                    '&.MuiInputBase-root .filter': {
                        height: 35,
                    },
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                lineHeight: 1.5,
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    '@media (min-width: 0px)': {
                        paddingLeft: 0,
                        '& .MuiBox-root h1': { width: '240px' },
                    },
                },
            },
        },
    };
}
