import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Alert, Box, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FC, MouseEventHandler, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '../../hooks';
import { FormGrid } from '../form-grid/form-grid.component';
import { Loading } from '../loading/loading.component';

interface Props {
    children: ReactNode;
    title?: ReactNode;
    filter?: ReactNode;
    subTitle?: ReactNode;
    actions?: ReactNode | ReactNode[];
    onBack?: MouseEventHandler;
    loading?: boolean;
}

export const Page: FC<Props> = ({ actions, onBack, title, subTitle, filter, children, loading }) => {
    const { t } = useTranslation();
    const mappedActions = useMemo(() => (Array.isArray(actions) ? actions : [actions]), [actions]);
    const lg = useBreakpoint('lg');

    return (
        <Box>
            {title && (
                <>
                    <Box sx={{ backgroundColor: 'white', px: 3, mt: 2, '.MuiFormControl-root': { mb: 0 } }}>
                        <FormGrid
                            sx={{ '&.MuiGrid-item': { pr: 0, pl: 1, pt: 0 } }}
                            containerProps={{
                                width: '100%',
                                minHeight: '80px',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'nowrap',
                            }}
                        >
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {onBack && (
                                    <IconButton
                                        color="inherit"
                                        aria-label="back button"
                                        onClick={onBack}
                                        size="large"
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                )}

                                <Typography variant="subtitle1" color="text.secondary" px={2}>
                                    {title}
                                    {subTitle && (
                                        <Typography variant="subtitle2" color="text.secondary">
                                            {subTitle}
                                        </Typography>
                                    )}
                                </Typography>

                                {filter}
                            </Stack>
                            {mappedActions && (
                                <Grid
                                    container
                                    flexWrap="nowrap"
                                    spacing={1}
                                    sx={{ width: 'fit-content', '.MuiGrid-item': { pr: 0 } }}
                                >
                                    {mappedActions.map((action, index) => (
                                        <Grid item key={index}>
                                            {action}
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </FormGrid>
                    </Box>
                    <Divider />
                </>
            )}

            <Box
                sx={{
                    p: 3,
                    maxHeight: title ? 'calc(100vh - 140px)' : 'calc(100vh - 60px)',
                    overflow: 'auto',
                    pb: lg ? '0' : '75px',
                }}
            >
                {window.carity.environment.testingBanner && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        {t('testingEnvironment')}
                    </Alert>
                )}
                {loading ? <Loading /> : children}
            </Box>
        </Box>
    );
};
