import React, { FC } from 'react';
import { ControlledCheckbox, ControlledInput, Widget } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';

export const GeneralSettings: FC = () => {
    const { t } = useTranslation();
    const form = useFormContext();

    return (
        <Widget title={t('generalSettings')}>
            <Stack spacing={2}>
                <ControlledCheckbox control={form.control} name="showNumpad" label={t('showNumpadMessage')} />

                <ControlledInput
                    label={t('saleTicketMessage')}
                    name={'saleTicketMessage'}
                    sx={{ '.MuiInputBase-root': { height: 'fit-content' } }}
                    rows={6}
                    multiline
                />
            </Stack>
        </Widget>
    );
};
