import {
    IProduct,
    IProductForm,
    ISaveProduct,
    mapAmountFromApi,
    mapAmountToApi,
    mapPriceFromApi,
    mapPriceToApi,
} from '../index';
import { ProductTaxes } from '../../admin/enums';

export const productToFormMapper = (product: IProduct): IProductForm => {
    return {
        ...product,
        categoryId: product.category?.id,
        shopCategoryIds: product.shopCategories?.map(({ id }) => id),
        tax: product.tax?.toString() as ProductTaxes,
        price: product.price,
        supplierId: product.supplier?.id,
        articleNumberSupplier: product.articleNumberSupplier || '',
        stockTarget: mapAmountFromApi(product.measurementUnit, product?.stockTarget),
        standardOrderAmount: mapAmountFromApi(product.measurementUnit, product?.standardOrderAmount),
    };
};

export const productFromFormMapper = (product: IProductForm, organisationId: string): ISaveProduct => {
    return {
        ...product,
        organisationId: organisationId,
        price: mapPriceToApi(product.measurementUnit, product?.price),
        solidarityPrice: mapPriceToApi(product.measurementUnit, product?.solidarityPrice),
        tax: parseInt(product.tax),
        stockTarget: mapAmountToApi(product.measurementUnit, product?.stockTarget),
        standardOrderAmount: mapAmountToApi(product.measurementUnit, product?.standardOrderAmount),
    };
};

export const productMapper = (product: IProduct): IProduct => {
    return {
        ...product,
        price: mapPriceFromApi(product.measurementUnit, product?.price),
        solidarityPrice: mapPriceFromApi(product.measurementUnit, product?.solidarityPrice),
        lastPurchasePrice: mapPriceFromApi(product.measurementUnit, product?.lastPurchasePrice),
    };
};
