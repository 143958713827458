import React, { FC, useMemo } from 'react';
import { FormGrid, IParams, LabelValue, Page, useSelectedOrganisation, Widget } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSale } from '../../../shop/hooks';
import { Link, Typography } from '@mui/material';
import { SaleDetailInformation, SaleDetailPayments, SaleDetailSaleItems } from '../../components';
import { useHasSupportCaseAccess } from '../../../customer/utils/has-support-case-access.util';
import { useCustomerInfo } from '../../../customer/hooks/customers.hook';
import { ISupportCaseInfo } from '../../../customer/models/support-case.model';
import { ICustomer } from '../../../customer/models/customer.model';
import { CustomerType } from '../../../customer/enums/customer-type.enum';

export const SaleDetail: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { id } = useParams<keyof IParams>() as IParams;
    const navigate = useNavigate();

    const { data: sale, isPending: saleIsPending } = useGetSale(id);
    const { data: customerInfo, isLoading: customerInfoIsLoading } = useCustomerInfo({
        id: sale?.supportCaseId || sale?.customerId,
        code: sale?.cardCode,
        organisation: organisation?.id,
    });

    const supportCase = customerInfo?.type === CustomerType.SOCIAL ? (customerInfo as ISupportCaseInfo) : undefined;
    const hasSupportCaseAccess = useHasSupportCaseAccess(supportCase, organisation);

    const cardCode = supportCase ? supportCase?.cardCode : (customerInfo as ICustomer)?.card.code;
    const primary = useMemo(() => supportCase?.supportees?.find((sup) => sup?.isPrimary), [supportCase]);
    const customerName = supportCase
        ? primary && `${primary?.firstName || t('anonymous')} ${primary?.lastName || ''}`
        : (customerInfo as ICustomer)?.name;

    return (
        <Page
            title={t('saleDetails')}
            onBack={() => navigate('/history/sales')}
            loading={saleIsPending || customerInfoIsLoading}
        >
            <FormGrid xs={12} sm={6}>
                <Widget
                    title={t('customerInfo')}
                    actions={
                        <>
                            {hasSupportCaseAccess && sale?.supportCaseId && (
                                <Link
                                    target="_blank"
                                    href={`${window.carity.environment.supportUrl}/support/support-cases/${sale?.supportCaseId}/detail`}
                                >
                                    <Typography variant="caption" fontWeight="bold">
                                        {t('goToDetailSupportCase')}
                                    </Typography>
                                </Link>
                            )}
                        </>
                    }
                >
                    <FormGrid xs={4}>
                        <LabelValue label={t('customerCard')} value={cardCode} />
                        <LabelValue label={t('customer')} value={customerName} />
                        <LabelValue label={t('type')} value={t(customerInfo?.type ?? CustomerType.SOLIDARITY)} />
                    </FormGrid>
                </Widget>

                <Widget title={t('saleInfo')}>{sale && <SaleDetailInformation sale={sale} />}</Widget>

                <Widget title={t('saleItems')} hasTable>
                    {sale?.saleItems && <SaleDetailSaleItems saleItems={sale.saleItems} />}
                </Widget>

                <Widget title={t('payments')} hasTable>
                    {sale?.payments && <SaleDetailPayments payments={sale.payments} />}
                </Widget>
            </FormGrid>
        </Page>
    );
};
