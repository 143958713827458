import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, number, object, string } from 'yup';
import { MeasurementUnit } from '../../shared';
import { ProductTaxes } from '../enums';

export function useProductSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                categoryId: string()
                    .transform((value) => (value === '' ? null : value))
                    .optional()
                    .nullable(),
                shopCategoryIds: array().of(string().required()).required(),
                barcodes: array()
                    .of(
                        string()
                            .min(4, t('minLengthBarcode', { length: '4' }))
                            .test('Should not contain -', (value, { createError }) =>
                                value?.includes('-')
                                    ? createError({
                                          message: t('mustNotContain', { value: '-' }),
                                      })
                                    : true,
                            )
                            .required(),
                    )
                    .required(),
                price: number()
                    .transform((value) => {
                        return Number.isNaN(value) ? null : value;
                    })
                    .when('hasPrice', {
                        is: true,
                        then: (schema) => schema.min(0, t('negativeNumber')).required(),
                    })
                    .nullable()
                    .optional(),
                solidarityPrice: number()
                    .transform((value) => {
                        return Number.isNaN(value) ? null : value;
                    })
                    .when('hasPrice', {
                        is: true,
                        then: (schema) => schema.min(0, t('negativeNumber')).required(),
                    })
                    .nullable()
                    .optional(),
                hasPrice: boolean().required(),
                tax: string()
                    .oneOf([...Object.values(ProductTaxes), ''])
                    .required(),
                measurementUnit: string()
                    .oneOf(Object.values(MeasurementUnit), t('requiredField', { fieldName: t('measurementUnit') }))
                    .required(),
                stockTarget: number()
                    .transform((value) => {
                        return value ? value : undefined;
                    })
                    .optional(),
                standardOrderAmount: number()
                    .transform((value) => {
                        return value ? value : 0;
                    })
                    .optional(),
                amountPerColli: number()
                    .transform((value) => {
                        return value ? value : null;
                    })
                    .optional()
                    .nullable(),
                supplierId: string()
                    .transform((value) => (value === '' ? null : value))
                    .optional()
                    .nullable(),
                articleNumberSupplier: string().optional(),
                storeOrder: number()
                    .transform((value) => {
                        return value ? value : null;
                    })
                    .optional()
                    .nullable(),
                excludeFromBudget: boolean().required(),
            }),
        [t],
    );
}
