import { Search } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HistoryFilterSales } from '../../../admin/components';
import {
    formatDateTimeString,
    formatPrice,
    mapDateStringToApiDate,
    Page,
    SaleStatus,
    SearchInput,
    SortOrder,
    TableComponent,
    useQueryParamsContext,
    useSelectedOrganisation,
} from '../../../shared';
import { useGetSalesList } from '../../../shop/hooks';
import { CustomerType } from '../../../customer/enums/customer-type.enum';

export const SalesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organisation } = useSelectedOrganisation();
    const {
        queryParams: { page, pageSize, sort, search, filter },
        debouncedQueryParams,
        setFilter,
        setPage,
        setPageSize,
        setSort,
        setSearch,
    } = useQueryParamsContext('sales', { sort: [{ field: 'updatedDate', sort: 'desc' }] });

    const { data: sales, isPending } = useGetSalesList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        organisationId: organisation?.id,
        search: debouncedQueryParams.search,
        updatedDate: mapDateStringToApiDate(filter?.updatedDate),
        status: filter?.status || undefined,
        types: filter?.types || undefined,
    });

    const columns: GridColDef[] = [
        {
            field: 'updatedDate',
            headerName: t('saleEndDate'),
            flex: 1,
            minWidth: 100,
            valueFormatter: (value: string, row) =>
                row.status === SaleStatus.COMPLETED ? formatDateTimeString(value) : '',
        },
        {
            field: 'cardCode',
            headerName: t('cardCode'),
            flex: 1,
            minWidth: 100,
            sortable: false,
            valueFormatter: (value: string) => value || '-',
        },
        {
            field: 'customerType',
            headerName: t('customerType'),
            flex: 1,
            minWidth: 100,
            sortable: false,
            valueFormatter: (value: CustomerType) => t(value),
        },
        {
            field: 'status',
            headerName: t('status'),
            flex: 1,
            minWidth: 100,
            sortable: false,
            valueFormatter: (value: SaleStatus) => t(value),
        },
        {
            field: 'totalPrice',
            headerName: t('totalPrice'),
            sortable: false,
            flex: 0.5,
            minWidth: 100,
            valueFormatter: (value: number) => (value ? formatPrice(value) : '-'),
        },
        {
            field: ' ',
            headerName: '',
            width: 50,
            flex: 0.3,
            align: 'center',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <IconButton
                    onClick={(event) => {
                        event.stopPropagation();
                        navigate(`${row?.id}`);
                    }}
                >
                    <Search />
                </IconButton>
            ),
        },
    ];

    const actions: ReactNode[] = [
        <SearchInput value={search} setValue={setSearch} placeholder={t(`searchCustomerCard`)} />,
    ];

    return (
        <Page title={t('sales')} filter={<HistoryFilterSales filter={filter} onChange={setFilter} />} actions={actions}>
            <TableComponent
                rows={sales?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={sales?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />
        </Page>
    );
};
