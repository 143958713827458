import { PersonSearchTwoTone } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionKeys, useHasPermission, Widget } from '../../../shared';
import { SearchOtherCustomerModal } from './search-other-customer-modal.component';
import { SearchSocialCustomerModal } from './search-social-customer-modal.component';

export const SearchCustomer: FC = () => {
    const { t } = useTranslation();
    const [showOtherSearch, setShowOtherSearch] = useState(false);
    const [showSocialCustomerSearch, setShowSocialCustomerSearch] = useState(false);
    const { hasPermission } = useHasPermission();
    const hasSupportCasesPermission = hasPermission(PermissionKeys.SUPPORT_CASES_READ);

    return (
        <Widget
            sx={{ maxWidth: 400, margin: 'auto', mt: 2, height: 290 }}
            title={t('searchCustomer')}
            icon={<PersonSearchTwoTone color="primary" />}
            variant="subtitle1"
            footer={
                <Stack spacing={2} width="100%">
                    <Button
                        onClick={() => setShowOtherSearch(true)}
                        variant="outlined"
                        size="large"
                        sx={{ width: '100%' }}
                    >
                        {t('searchOtherCustomer')}
                    </Button>
                    <Button
                        onClick={() => setShowSocialCustomerSearch(true)}
                        variant="contained"
                        size="large"
                        fullWidth
                        disabled={!hasSupportCasesPermission}
                    >
                        {t('searchSocialCustomer')}
                    </Button>
                </Stack>
            }
        >
            <Typography variant="caption">{t('searchCustomerInfo')}</Typography>

            {showOtherSearch && (
                <SearchOtherCustomerModal open={showOtherSearch} handleClose={() => setShowOtherSearch(false)} />
            )}

            {showSocialCustomerSearch && (
                <SearchSocialCustomerModal
                    open={showSocialCustomerSearch}
                    handleClose={() => setShowSocialCustomerSearch(false)}
                />
            )}
        </Widget>
    );
};
