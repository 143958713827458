import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect } from '../controlled-select/controlled-select.component';

interface Props {
    name: string;
    label: string;
    form: UseFormReturn;
    options: { label: string; value: string }[];
    multiple?: boolean;
}

export const ControlledSelectWithMenuItems: FC<Props> = ({ name, label, form, options, multiple = true }) => {
    const { t } = useTranslation();
    const currentValues = form.watch(name);

    const getOptionNameByValue = (value: string): string =>
        options?.find((option) => option.value === value)?.label || t('unknown');

    const formatSelectedValues = (values: string[]) =>
        options?.length === values?.length ? t('all') : values?.map(getOptionNameByValue).join(', ');

    return (
        <ControlledSelect
            name={name}
            label={label}
            control={form.control}
            multiple={multiple}
            items={options.map(({ value }) => value)}
            renderValue={multiple ? (values) => formatSelectedValues(values as string[]) : undefined}
            size="small"
            className="filter"
        >
            {multiple && (
                <MenuItem value="all">
                    <Checkbox checked={currentValues?.length === (options?.length || 0)} />
                    <ListItemText primary={t('all')} />
                </MenuItem>
            )}
            {options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                    {multiple && <Checkbox checked={currentValues?.includes(option.value) || false} />}

                    <ListItemText primary={option.label} />
                </MenuItem>
            ))}
        </ControlledSelect>
    );
};
