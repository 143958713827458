import { QrCode2Outlined, Sell } from '@mui/icons-material';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import React, { FC, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    productId: string;
    type: 'barcode' | 'price';
    variant?: 'icon' | 'icon-button';
}

export const DownloadLabels: FC<Props> = ({ productId, type, variant }) => {
    const { t } = useTranslation();

    const translation = t(type === 'barcode' ? 'downloadBarcodeLabel' : 'downloadPriceLabel');
    const action: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            if (type === 'barcode') window.open(`/shop-api/labels/${productId}/barcode`, '__blank');
            else if (type === 'price') window.open(`/shop-api/labels/${productId}/price`, '__blank');
            e.stopPropagation();
        },
        [productId, type],
    );

    switch (variant) {
        case 'icon':
            return (
                <Tooltip title={<Typography sx={{ fontSize: '13px' }}>{translation}</Typography>} arrow>
                    <IconButton onClick={action}>{type === 'barcode' ? <QrCode2Outlined /> : <Sell />} </IconButton>
                </Tooltip>
            );
        case 'icon-button':
            return (
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={action}
                    size="small"
                    startIcon={type === 'barcode' ? <QrCode2Outlined /> : <Sell />}
                >
                    {translation}
                </Button>
            );
        default:
            return (
                <Button color="secondary" variant="outlined" onClick={action}>
                    {translation}
                </Button>
            );
    }
};
