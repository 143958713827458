import scanner from 'onscan.js';
import React, { FC, useEffect } from 'react';
import { Page, useCurrentRegisterSession, useSelectedOrganisation, useShop } from '../../../shared';
import { Register, StartNewSale, StartRegisterSession } from '../../components';
import { useCurrentSale, useOnScan } from '../../hooks';
import { CustomerType } from '../../../customer/enums/customer-type.enum';
import { useCustomerInfo } from '../../../customer/hooks/customers.hook';
import { ISupportCaseInfo } from '../../../customer/models/support-case.model';
import { ICustomer } from '../../../customer/models/customer.model';

export const ShopPage: FC = () => {
    const { organisation } = useSelectedOrganisation();
    const { setShowWarningNoCustomer, customer, setCustomer, customerCredentials, setCustomerCredentials } = useShop();

    const { data: currentSale } = useCurrentSale(organisation?.id);
    const { data: currentRegisterSession, isPending: currentRegisterSessionIsPending } = useCurrentRegisterSession(
        organisation?.id,
    );

    const {
        data: customerInfo,
        isPending,
        isError: noCustomerInfo,
    } = useCustomerInfo({
        id: customerCredentials?.supportCaseId || customerCredentials?.customerId,
        code: customerCredentials?.cardCode,
        isSocialCustomer: customerCredentials?.isSocialCustomer,
        organisation: organisation?.id,
    });

    useEffect(() => {
        setShowWarningNoCustomer(noCustomerInfo);
    }, [noCustomerInfo, setShowWarningNoCustomer]);

    useEffect(() => {
        if (currentSale?.id && !customerCredentials)
            setCustomerCredentials({
                supportCaseId: currentSale?.supportCaseId,
                customerId: currentSale?.customerId,
                cardCode: currentSale?.cardCode,
            });
    }, [
        currentSale?.id,
        currentSale?.supportCaseId,
        currentSale?.customerId,
        currentSale?.cardCode,
        customerCredentials,
        setCustomerCredentials,
    ]);

    useEffect(() => {
        if (customerInfo) {
            if (customerInfo.type === CustomerType.SOCIAL) {
                const { supportCaseId, cardCode, type, supportees } = customerInfo as ISupportCaseInfo;
                setCustomer({ supportCaseId, cardCode, type, amountOfPeople: supportees?.length });
            } else {
                const { id, card, type } = customerInfo as ICustomer;
                setCustomer({ customerId: id, cardCode: card.code, type });
            }
        }
    }, [setCustomer, customerInfo]);

    const onScan = useOnScan();
    useEffect(() => {
        scanner.attachTo(document, { onScan, minLength: 4 });
        return () => scanner.detachFrom(document);
    }, [onScan]);

    const loading = (isPending && !!customerCredentials && !!organisation) || currentRegisterSessionIsPending;
    const hasSale = customer?.type !== undefined || currentSale;

    return (
        <Page loading={loading}>
            {!currentRegisterSession ? (
                <StartRegisterSession />
            ) : (
                <>{!hasSale ? <StartNewSale /> : <Register currentSale={currentSale} customerInfo={customerInfo} />}</>
            )}
        </Page>
    );
};
