import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelValue } from '../../../../shared';
import { FamilySituationInfo } from './family-situation-info.component';
import { IInfoSupportee } from '../../../models/supportee.model';
import { ISupportCaseInfo } from '../../../models/support-case.model';

type Supportees = { adult: IInfoSupportee[]; child: IInfoSupportee[]; other: IInfoSupportee[] };

interface Props {
    supportCase: ISupportCaseInfo;
}

export const FamilySituation: FC<Props> = ({ supportCase }) => {
    const { t } = useTranslation();
    const { adult, child, other }: Supportees = { adult: [], child: [], other: [] };

    if (supportCase.supportees) {
        for (const supportee of supportCase.supportees) {
            if (supportee.age || supportee.age === 0) {
                if (supportee.age < 18) child.push(supportee);
                else adult.push(supportee);
            } else if (supportee.relation) {
                if (supportee.relation.toLowerCase() === 'child') child.push(supportee);
                else adult.push(supportee);
            } else other.push(supportee);
        }
    }

    return (
        <>
            {supportCase && (
                <Stack spacing={1}>
                    {adult?.length > 0 && (
                        <LabelValue
                            label={t('adults')}
                            value={<FamilySituationInfo supportees={adult?.sort((a) => (a.isPrimary ? -1 : 1))} />}
                        />
                    )}

                    {child?.length > 0 && (
                        <LabelValue label={t('children')} value={<FamilySituationInfo supportees={child} />} />
                    )}

                    {other?.length > 0 && (
                        <LabelValue
                            label={t('ageUnknown')}
                            value={<FamilySituationInfo supportees={other?.sort((a) => (a.isPrimary ? -1 : 1))} />}
                        />
                    )}
                </Stack>
            )}
        </>
    );
};
