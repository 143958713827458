import { ICreateSaleItem, ICustomerContext, IProduct, MeasurementUnit, SelectedProductType } from '../../shared';
import { getProductPrice } from '../utils/product-price.util';

export const saleItemFromFormMapper = (
    product: IProduct | SelectedProductType,
    organisationId: string,
    amount: number,
    price: number,
    customer?: ICustomerContext,
): ICreateSaleItem => {
    const isGram = product.measurementUnit === MeasurementUnit.GRAM;

    const alteredProductPrice = () => {
        if (!product.hasPrice || getProductPrice(customer, product) !== price) return isGram ? price / 1000 : price;
        return undefined;
    };

    return {
        productId: product.id || undefined,
        barcode: ('barcode' in product && product?.barcode) || undefined,
        organisationId: organisationId,
        cardCode: customer?.cardCode,
        supportCaseId: customer?.supportCaseId,
        customerId: customer?.customerId,
        amountOfPeople: customer?.amountOfPeople,
        customerType: customer?.type,
        amount: isGram ? amount * 1000 : amount,
        alteredProductPrice: alteredProductPrice(),
    };
};
