import { ListModel, ListQueryParam, supportApi } from '../../shared';
import { ISupportCase } from '../models/support-case.model';

class SupportCaseClient {
    public async getSupportCases(params: ListQueryParam, organisation?: string): Promise<ListModel<ISupportCase>> {
        const { data } = await supportApi.get('/support-cases', {
            params: { ...params, organisationId: organisation },
        });
        return data;
    }
}

export const supportCaseClient = new SupportCaseClient();
