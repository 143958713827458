import React, { FC } from 'react';
import { ControlledCheckbox, ControlledInput, Widget } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { InputAdornment, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const SolidaritySettings: FC = () => {
    const { t } = useTranslation();
    const form = useFormContext();
    const watchSolidarityPriceIsPurchasePrice = form.watch('solidarityPriceIsPurchasePrice');

    return (
        <Widget title={t('solidaritySettings')}>
            <Stack spacing={2}>
                <ControlledCheckbox
                    control={form.control}
                    name="showSolidarityPriceOnLabel"
                    label={t('showSolidarityPriceOnLabel')}
                />
                <ControlledCheckbox
                    control={form.control}
                    name="solidarityPriceIsPurchasePrice"
                    label={t('solidarityPriceIsPurchasePrice')}
                />
                {!watchSolidarityPriceIsPurchasePrice && (
                    <ControlledInput
                        type="number"
                        label={t('additionalSolidarityCharge')}
                        name="additionalSolidarityCharge"
                        InputProps={{
                            endAdornment: <InputAdornment position="end" children="%" />,
                        }}
                        sx={{
                            display: watchSolidarityPriceIsPurchasePrice ? 'none' : 'block',
                            maxWidth: '250px',
                        }}
                        required={!watchSolidarityPriceIsPurchasePrice}
                    />
                )}
            </Stack>
        </Widget>
    );
};
