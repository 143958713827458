import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AutocompleteProducts,
    ControlledCheckbox,
    ControlledInput,
    ControlledNumberInput,
    ControlledSelect,
    FormGrid,
    IReturnSaleItemForm,
    MeasurementUnit,
    useSelectedOrganisation,
    useShop,
} from '../../../shared';
import { validateCurrentAmount } from '../../utils';
import { useSettings } from '../../../admin/hooks';
import { Button, Checkbox, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { ProductTaxes } from '../../../admin/enums';
import { InputNumpad } from '../input-numpad/input-numpad.component';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReturnProduct } from '../../validators';
import { useCreateManualSaleItem, useCreateSaleItem } from '../../hooks';
import { saleItemFromFormMapper } from '../../mappers';
import { getProductPrice } from '../../utils/product-price.util';

export const ReturnProduct: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { data: settings } = useSettings(organisation?.id);
    const { customer } = useShop();

    const { mutateAsync: createManualSaleItem } = useCreateManualSaleItem();
    const { mutateAsync: saveSaleItem } = useCreateSaleItem();

    const [selectedField, setSelectedField] = useState<string>('product');
    const [productPrice, setProductPrice] = useState<string>('');
    const [amount, setAmount] = useState<string>('');
    const [manualProductInput, setManualProductInput] = useState(false);

    const form = useForm<IReturnSaleItemForm>({
        resolver: yupResolver(useReturnProduct()),
    });
    const product = form.watch('product');

    const resetValues = useCallback(() => {
        form.reset({ tax: 0, productName: '', excludeFromBudget: false });
        setProductPrice('');
        setAmount('');
    }, [form]);

    useEffect(() => {
        resetValues();
    }, [form, resetValues]);

    useEffect(() => {
        if (manualProductInput) {
            setAmount('');
            setProductPrice('');
        }
    }, [manualProductInput]);

    useEffect(() => {
        if (product !== undefined) {
            setAmount('');
            setProductPrice(getProductPrice(customer, product)?.toString() ?? '');
        }
    }, [customer, product]);

    const onNumpadInput = useCallback(
        (input: string) => {
            if (selectedField === 'productPrice') return setProductPrice(input);
            if (selectedField === 'amount') return setAmount(input);
        },
        [selectedField],
    );

    const disableInputOptions = useMemo(() => {
        return selectedField === 'productPrice'
            ? validateCurrentAmount(productPrice, undefined, true)
            : validateCurrentAmount(amount, product?.measurementUnit || undefined);
    }, [selectedField, productPrice, amount, product]);

    const setFormValuesOnSubmit = () => {
        form.setValue('productPrice', parseFloat(productPrice), { shouldValidate: true });
        form.setValue('amount', parseFloat(amount), { shouldValidate: true });
    };

    const handleSubmit = async (item: IReturnSaleItemForm) => {
        if (organisation) {
            if (manualProductInput && customer) {
                const { supportCaseId, customerId, cardCode, amountOfPeople } = customer;
                await createManualSaleItem({
                    organisationId: organisation.id,
                    supportCaseId,
                    customerId,
                    cardCode,
                    amountOfPeople,
                    ...item,
                    productName: item.productName ? item.productName : t('generalSale'),
                    productPrice: item.productPrice,
                    amount: -item.amount,
                    excludeFromBudget: item.excludeFromBudget,
                });
            } else {
                if (item?.product) {
                    await saveSaleItem(
                        saleItemFromFormMapper(
                            item.product,
                            organisation.id,
                            -item.amount,
                            item.productPrice,
                            customer,
                        ),
                    );
                }
            }
        }
        resetValues();
    };

    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                    <Stack direction="row">
                        {manualProductInput ? (
                            <ControlledInput
                                name="productName"
                                label={t('productName')}
                                placeholder={t('generalSale')}
                            />
                        ) : (
                            <Stack direction="column" sx={{ width: '100%', '.MuiInputBase-root': { mb: 3 } }}>
                                <Typography mb={'5px'}>
                                    {t('product')} <span>*</span>
                                </Typography>
                                <AutocompleteProducts
                                    selectedProduct={product}
                                    setSelectedProduct={(item) => {
                                        if (item !== null) form.setValue('product', item, { shouldValidate: true });
                                    }}
                                    useOnScan={false}
                                />
                            </Stack>
                        )}
                        <Stack direction="row">
                            <Typography sx={{ mt: 3.5, textAlign: 'center' }}>{t('manualInput')}</Typography>
                            <Checkbox
                                checked={manualProductInput}
                                onChange={() => {
                                    setManualProductInput(!manualProductInput);
                                    resetValues();
                                }}
                            />
                        </Stack>
                    </Stack>

                    <FormGrid sm={12} md={4} justifyContent="center">
                        <ControlledNumberInput
                            name="productPrice"
                            value={product && !productPrice && !manualProductInput ? product.price : productPrice || ''}
                            label={t('productPrice')}
                            required
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={2}
                            onSelect={() => setSelectedField('productPrice')}
                            onChange={(input) => setProductPrice(input.target.value)}
                        />
                        <ControlledNumberInput
                            name="amount"
                            value={amount}
                            label={t('amount')}
                            required
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={
                                manualProductInput || product?.measurementUnit !== MeasurementUnit.GRAM ? 0 : 3
                            }
                            onSelect={() => setSelectedField('amount')}
                            onChange={(input) => {
                                setAmount(input.target.value);
                            }}
                        />
                        {manualProductInput ? (
                            <ControlledSelect name="tax" label={t('productTax')}>
                                {Object.values(ProductTaxes)?.map((value) => (
                                    <MenuItem value={parseInt(value)} key={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        ) : (
                            <TextField value={form.getValues('product')?.tax || 0} disabled label={t('productTax')} />
                        )}
                        {manualProductInput && customer?.supportCaseId && (
                            <ControlledCheckbox
                                name="excludeFromBudget"
                                label={t('excludeFromBudgetText')}
                                control={form.control}
                            />
                        )}
                    </FormGrid>

                    <Stack direction="column" spacing={2} alignItems="center">
                        <InputNumpad
                            value={selectedField === 'amount' ? amount : productPrice}
                            onChange={onNumpadInput}
                            disable={disableInputOptions}
                            showNumpad={settings?.showNumpad}
                        />

                        <Button
                            sx={{ width: '100%' }}
                            variant="contained"
                            onClick={setFormValuesOnSubmit}
                            type="submit"
                        >
                            {t('returnProduct')}
                        </Button>
                    </Stack>
                </form>
            </FormProvider>
        </>
    );
};
