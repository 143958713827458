import React, { FC } from 'react';
import { Section } from '../section/section.component';
import { FormGrid } from '../form-grid/form-grid.component';
import { ControlledNumberInput } from '../controlled-number-input/controlled-number-input.component';
import { MeasurementUnit } from '../../enums';
import { InputAdornment, MenuItem } from '@mui/material';
import { ControlledSelect } from '../controlled-select/controlled-select.component';
import { ISupplier } from '../../models';
import { ControlledInput } from '../controlled-input/controlled-input.component';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

interface Props {
    prefixName: string;
    suppliers: ISupplier[];
}

export const ProductStockInputs: FC<Props> = ({ prefixName, suppliers }) => {
    const { t } = useTranslation();
    const form = useFormContext();
    const watchMeasurementUnit = form.watch(`${prefixName}measurementUnit`);

    return (
        <Section title={t('productStockDetails')}>
            <FormGrid sm={12} md={6}>
                <ControlledNumberInput
                    name={`${prefixName}stockTarget`}
                    label={t('minExpectedStockTarget')}
                    decimalScale={watchMeasurementUnit === MeasurementUnit.GRAM ? 3 : 0}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    children={
                                        watchMeasurementUnit === MeasurementUnit.GRAM
                                            ? t('abbrevKilogram')
                                            : t('abbrevPiece')
                                    }
                                />
                            ),
                        },
                    }}
                />
                <ControlledNumberInput
                    name={`${prefixName}standardOrderAmount`}
                    label={t('standardOrderAmount')}
                    decimalScale={watchMeasurementUnit === MeasurementUnit.GRAM ? 3 : 0}
                />
                <ControlledNumberInput
                    name={`${prefixName}amountPerColli`}
                    label={t('amountPerColli')}
                    decimalScale={0}
                />
                <ControlledNumberInput name={`${prefixName}storeOrder`} label={t('storeOrder')} decimalScale={0} />
                <ControlledSelect name={`${prefixName}supplierId`} label={t('standardSupplier')}>
                    <MenuItem value={''} key={''} sx={{ height: '30px' }} />
                    {suppliers.map((supplier: ISupplier) => (
                        <MenuItem value={supplier.id} key={supplier.id}>
                            {supplier.name}
                        </MenuItem>
                    ))}
                </ControlledSelect>
                <ControlledInput name={`${prefixName}articleNumberSupplier`} label={t('articleNumberSupplier')} />
            </FormGrid>
        </Section>
    );
};
