import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { PermissionKeys, ProtectedRoute, SideBar, SideNav, useHasPermission } from '../../../shared';
import { stockSubRoutes } from '../../stock.routes';

export const StockPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasPurchaseReadPermission = hasPermission(PermissionKeys.PURCHASES_READ);

    useEffect(() => {
        document.title = t('pages.stock');
    }, [t]);

    useEffect(() => {
        if (!hasPurchaseReadPermission) navigate(`/stock/stock-movements`);
    }, [hasPurchaseReadPermission, navigate]);

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                {hasPurchaseReadPermission && <SideNav items={[{ path: '/stock/purchases', text: t('purchases') }]} />}
                <SideNav items={[{ path: '/stock/stock-movements', text: t('stockMovements') }]} />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {stockSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    {<subRoute.component />}
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
