import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ListModel, ListQueryParam, QueryKeys } from '../../shared';
import { ISupportCase } from '../models/support-case.model';
import { supportCaseClient } from '../clients/support-case.client';

const client = supportCaseClient;
const listKey = QueryKeys.SUPPORT_CASES;

export function useSupportCases(params: ListQueryParam, enabled: boolean, organisation?: string) {
    return useQuery<ListModel<ISupportCase>, AxiosError>({
        queryKey: [listKey, params, { organisation: organisation }],
        queryFn: () => client.getSupportCases(params, organisation),
        enabled: enabled,
    });
}
